import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthModule } from '@capturum/auth';
import {
  BaseDataModule as CapturumBaseDataModule,
  SettingModule as CapturumSettingModule,
  CompleteModule,
  TranslationLoader,
} from '@capturum/complete';
import { CustomMissingTranslationHandler } from '@core/translate/custom-missing-translation-handler';
import { FormlyModule } from '@ngx-formly/core';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { DeviceAvailabilityCalendarComponent } from '@shared/components/device-availability-calendar/device-availability-calendar.component';
import { DeviceCardComponent } from '@shared/components/device-card/device-card.component';
import { FilteredDeviceGroupListComponent } from '@shared/components/device-group-list/device-group-list.component';
import { FormCardComponent } from '@shared/components/form-card/form-card.component';
import { HtmlContentCardComponent } from '@shared/components/html-content-card/html-content-card.component';
import { LoaderComponent } from '@shared/components/loader/loader.component';
import { ManageDetailComponent } from '@shared/components/manage-detail/manage-detail.component';
import { ManageHeaderComponent } from '@shared/components/manage-header/manage-header.component';
import { ManageListComponent } from '@shared/components/manage-list/manage-list.component';
import { MenuButtonComponent } from '@shared/components/menu-button/menu-button.component';
import { NoResultsComponent } from '@shared/components/no-results/no-results.component';
import { PageFiltersComponent } from '@shared/components/page-filters/page-filters.component';
import { RightSidebarComponent } from '@shared/components/right-sidebar/right-sidebar.component';
import { SearchResultsCardComponent } from '@shared/components/search-results-card/search-results-card.component';
import { SelectableItemsListComponent } from '@shared/components/selectable-items-list/selectable-items-list.component';
import { CurrencyInputFormlyTypeComponent } from '@shared/formly/types/currency-input/currency-input.formly-type';
import { InputFormlyTypeComponent } from '@shared/formly/types/input/input.formly-type';
import { MultiSelectFormlyTypeComponent } from '@shared/formly/types/multi-select/multi-select.formly-type';
import { AssetsPipe } from '@shared/pipes/assets.pipe';
import { UserFullNamePipe } from '@shared/pipes/user-full-name.pipe';
import { UserShortNamePipe } from '@shared/pipes/user-short-name.pipe';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SidebarModule } from 'primeng/sidebar';
import { ManageDetailStateComponent } from './components/manage-detail-state/manage-detail-state.component';
import { NavigationButtonComponent } from './components/navigation-button/navigation-button.component';
import { FileDragAndDropDirective } from './directives/file-drag-and-drop.directive';
import { RepeatSectionFormlyTypeComponent } from './formly/types/repeat-section/repeat-section-formly-type.component';
import { ToUtcDatePipe } from './pipes/to-utc-date.pipe';

import { CapturumSharedModule } from '@capturum/ui/api';
import { CapturumButtonModule } from '@capturum/ui/button';
import { CapturumCalendarModule } from '@capturum/ui/calendar';
import { CapturumCardModule } from '@capturum/ui/card';
import { CapturumCheckboxModule } from '@capturum/ui/checkbox';
import { CollapsableCardModule } from '@capturum/ui/collapsable-card';
import { CapturumDropdownModule } from '@capturum/ui/dropdown';
import { CapturumEditorModule } from '@capturum/ui/editor';
import { CapturumFilePreviewListModule } from '@capturum/ui/file-preview-list';
import { CapturumInfoTableModule } from '@capturum/ui/info-table';
import { CapturumInputModule } from '@capturum/ui/input';
import { CapturumInputSwitchModule } from '@capturum/ui/input-switch';
import { CapturumMultiSelectModule } from '@capturum/ui/multi-select';
import { CapturumSelectButtonModule } from '@capturum/ui/select-button';
import { CapturumSkeletonModule } from '@capturum/ui/skeleton';
import { CapturumTextareaModule } from '@capturum/ui/textarea';
import { CapturumToastModule } from '@capturum/ui/toast';
import { InputMaskFormlyTypeComponent } from '@shared/formly/types/input-mask/input-mask.formly-type';
import { InputSwitchFormlyTypeComponent } from '@shared/formly/types/input-switch/input-switch.formly-type';
import { RadioFormlyTypeComponent } from '@shared/formly/types/radio/radio.formly-type';
import { RadboudFormFieldWrapperComponent } from '@shared/formly/wrappers/radboud-form-field/radboud-form-field.wrapper';
import { ArraySortPipe } from '@shared/pipes/array-sort.pipe';
import { BookingDatePipe } from '@shared/pipes/booking-date.pipe';
import { ConcatenatePipe } from '@shared/pipes/concatenate.pipe';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { HideBookingActionByKeyPipe } from '@shared/pipes/hide-action-by-key.pipe';
import { StripHtmlPipe } from '@shared/pipes/strip-html.pipe';
import { TextEllipsisPipe } from '@shared/pipes/text-ellipsis.pipe';
import { ImageActions } from '@xeger/quill-image-actions';
import { ImageFormats } from '@xeger/quill-image-formats';
import { InputMaskModule } from 'primeng/inputmask';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TooltipModule } from 'primeng/tooltip';
import QuillImageDropAndPaste from 'quill-image-drop-and-paste';
import ImageUploader from 'quill-image-uploader';
import { FilesUploaderComponent } from './components/files-uploader/files-uploader.component';
import { LoginModalComponent } from './components/login-modal/login-modal.component';
import { CalendarFormlyTypeComponent } from './formly/types/calendar/calendar.formly-type';
import { DropdownFormlyTypeComponent } from './formly/types/dropdown/dropdown.formly-type';
import { EditorFormlyTypeComponent } from './formly/types/editor/editor.formly-type';
import { MultiSelectGroupedFormlyTypeComponent } from './formly/types/multi-select-group/multi-select-group.formly-type';
import { RadboudInputSwitchWrapperComponent } from './formly/wrappers/radboud-input-switch-wrapper/radboud-input-switch.wrapper';
import { BookingDateDifference } from './pipes/booking-date-difference.pipe';
import { DeviceImagePipe } from './pipes/device-image.pipe';
import { LabelFromOptionsPipe } from './pipes/label-from-options.pipe';
import { SortByLabelPipe } from './pipes/sort-by-label.pipe';
import { ObjectFullName } from './pipes/object-full-name.pipe';
import { SafeDatePipe } from './pipes/safe-date.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { DecimalInputFormlyTypeComponent } from '@shared/formly/types/decimal-input/decimal-input.formly-type';

@NgModule({
  imports: [
    CommonModule,
    NgxPermissionsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationLoader,
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler,
      },
    }),
    FormsModule,
    ReactiveFormsModule,
    AuthModule,
    AccordionModule,
    OverlayPanelModule,
    RadioButtonModule,
    ConfirmDialogModule,
    InputNumberModule,
    SidebarModule,
    InputMaskModule,
    CompleteModule,
    CapturumBaseDataModule,
    CapturumSettingModule,
    CapturumSharedModule,
    CapturumSelectButtonModule,
    CapturumFilePreviewListModule,
    CapturumInputModule,
    CapturumButtonModule,
    CapturumToastModule,
    CapturumCardModule,
    CapturumToastModule,
    CapturumInputSwitchModule,
    CapturumEditorModule.forRoot({
      customModules: [
        { path: 'modules/imageUploader', implementation: ImageUploader },
        { path: 'modules/imageActions', implementation: ImageActions },
        { path: 'modules/imageFormats', implementation: ImageFormats },
        { path: 'modules/customImageDropAndPaste', implementation: QuillImageDropAndPaste },
      ],
    }),
    CapturumMultiSelectModule,
    CapturumCalendarModule,
    CapturumDropdownModule,
    CapturumTextareaModule,
    CapturumInfoTableModule,
    CapturumSkeletonModule,
    CapturumCheckboxModule,
    CollapsableCardModule,
    TooltipModule,
    FormlyModule.forRoot({
      wrappers: [
        {
          name: 'radboud-form-field',
          component: RadboudFormFieldWrapperComponent,
        },
        {
          name: 'radboud-input-switch-wrapper',
          component: RadboudInputSwitchWrapperComponent,
        },
      ],
      types: [
        {
          name: 'input',
          component: InputFormlyTypeComponent,
          wrappers: ['radboud-form-field'],
        },
        {
          name: 'radboudInput',
          component: InputFormlyTypeComponent,
          wrappers: ['radboud-form-field'],
        },
        {
          name: 'inputMask',
          component: InputMaskFormlyTypeComponent,
          wrappers: ['radboud-form-field'],
        },
        {
          name: 'currency',
          component: CurrencyInputFormlyTypeComponent,
          wrappers: ['radboud-form-field'],
        },
        {
          name: 'decimal',
          component: DecimalInputFormlyTypeComponent,
          wrappers: ['radboud-form-field'],
        },
        {
          name: 'editor',
          component: EditorFormlyTypeComponent,
          wrappers: ['radboud-form-field'],
        },
        {
          name: 'multiSelect',
          component: MultiSelectFormlyTypeComponent,
          wrappers: ['radboud-form-field'],
        },
        {
          name: 'multiSelectGrouped',
          component: MultiSelectGroupedFormlyTypeComponent,
          wrappers: ['radboud-form-field'],
        },
        {
          name: 'radio',
          component: RadioFormlyTypeComponent,
          wrappers: ['radboud-form-field'],
        },
        {
          name: 'repeat',
          component: RepeatSectionFormlyTypeComponent,
          wrappers: ['radboud-form-field'],
        },
        {
          name: 'calendar',
          component: CalendarFormlyTypeComponent,
          wrappers: ['radboud-form-field'],
        },
        {
          name: 'inputSwitch',
          component: InputSwitchFormlyTypeComponent,
          wrappers: ['radboud-input-switch-wrapper'],
        },
        {
          name: 'customDropdown',
          component: DropdownFormlyTypeComponent,
          wrappers: ['cap-form-field'],
        },
      ],
    }),
    InputFormlyTypeComponent,
    InputMaskFormlyTypeComponent,
    RadioFormlyTypeComponent,
    CurrencyInputFormlyTypeComponent,
    DecimalInputFormlyTypeComponent,
    EditorFormlyTypeComponent,
    MultiSelectFormlyTypeComponent,
    InputSwitchFormlyTypeComponent,
    RadboudFormFieldWrapperComponent,
    LoaderComponent,
    HtmlContentCardComponent,
    SearchResultsCardComponent,
    NoResultsComponent,
    DeviceCardComponent,
    PageFiltersComponent,
    DeviceAvailabilityCalendarComponent,
    MenuButtonComponent,
    FormCardComponent,
    ManageListComponent,
    ManageDetailComponent,
    SelectableItemsListComponent,
    ManageHeaderComponent,
    UserShortNamePipe,
    UserFullNamePipe,
    RightSidebarComponent,
    ManageDetailStateComponent,
    AssetsPipe,
    FileDragAndDropDirective,
    ConcatenatePipe,
    LoginModalComponent,
    DeviceImagePipe,
    DefaultPipe,
    FilteredDeviceGroupListComponent,
    FilesUploaderComponent,
    RepeatSectionFormlyTypeComponent,
    NavigationButtonComponent,
    CalendarFormlyTypeComponent,
    ToUtcDatePipe,
    HideBookingActionByKeyPipe,
    SafeHtmlPipe,
    StripHtmlPipe,
    ArraySortPipe,
    SafeDatePipe,
    BookingDatePipe,
    ObjectFullName,
    RadboudInputSwitchWrapperComponent,
    MultiSelectGroupedFormlyTypeComponent,
    LabelFromOptionsPipe,
    BookingDatePipe,
    BookingDateDifference,
    TextEllipsisPipe,
    SortByLabelPipe,
  ],
  exports: [
    NgxPermissionsModule,
    TranslateModule,
    FormlyModule,
    FormsModule,
    ReactiveFormsModule,
    AuthModule,
    AccordionModule,
    OverlayPanelModule,
    ConfirmDialogModule,
    InputNumberModule,
    RadioButtonModule,
    SidebarModule,
    InputMaskModule,
    CompleteModule,
    CapturumBaseDataModule,
    CapturumSettingModule,
    CapturumSharedModule,
    CapturumSelectButtonModule,
    CapturumButtonModule,
    CapturumFilePreviewListModule,
    CapturumInputModule,
    CapturumCardModule,
    CapturumToastModule,
    CapturumInputSwitchModule,
    CapturumEditorModule,
    CapturumMultiSelectModule,
    CapturumCalendarModule,
    CapturumDropdownModule,
    CapturumTextareaModule,
    CapturumInfoTableModule,
    CapturumSkeletonModule,
    CollapsableCardModule,
    LoaderComponent,
    HtmlContentCardComponent,
    SearchResultsCardComponent,
    DeviceCardComponent,
    PageFiltersComponent,
    DeviceAvailabilityCalendarComponent,
    MenuButtonComponent,
    NoResultsComponent,
    FormCardComponent,
    SelectableItemsListComponent,
    ManageHeaderComponent,
    UserShortNamePipe,
    UserFullNamePipe,
    RightSidebarComponent,
    ManageDetailStateComponent,
    AssetsPipe,
    FileDragAndDropDirective,
    ConcatenatePipe,
    LoginModalComponent,
    DeviceImagePipe,
    DefaultPipe,
    FilteredDeviceGroupListComponent,
    FilesUploaderComponent,
    NavigationButtonComponent,
    ToUtcDatePipe,
    HideBookingActionByKeyPipe,
    SafeHtmlPipe,
    StripHtmlPipe,
    ArraySortPipe,
    SafeDatePipe,
    BookingDatePipe,
    ObjectFullName,
    BookingDateDifference,
    TextEllipsisPipe,
  ],
  providers: [TextEllipsisPipe],
})
export class SharedModule {}
