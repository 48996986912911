import { Pipe, PipeTransform } from '@angular/core';
import { MapItem } from '@capturum/ui/api';

@Pipe({
  name: 'sortByLabel',
  standalone: true,
})
export class SortByLabelPipe implements PipeTransform {
  public transform(selectedItems: string[], options: MapItem[]): string[] {
    const map = new Map(
      selectedItems.map(value => {
        const option = options.find(option => option.value === value);

        return [ value, option?.label || "" ];
      })
    );

    const sortedEntries = Array.from(map.entries()).sort(([, labelA], [, labelB]) =>
      labelA.localeCompare(labelB)
    );

    return sortedEntries.map(([ value ]) => value);
  }
}
