import { Component, ViewChild } from '@angular/core';
import { MapItem, CapturumSharedModule } from '@capturum/ui/api';
import { CapturumMultiSelectComponent, CapturumMultiSelectModule } from '@capturum/ui/multi-select';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import { LabelFromOptionsPipe } from '../../../pipes/label-from-options.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AsyncPipe } from '@angular/common';
import { SortByLabelPipe } from '../../../pipes/sort-by-label.pipe';

@Component({
  template: `
    @if (to.options | observablePipe | async; as options) {
      @if (!to.disabled) {
        <cap-multi-select
          [options]="options"
          [formlyAttributes]="field"
          [formControl]="formControl"
          [defaultLabel]="to.translatePlaceholder || to.placeholder | observablePipe | async"
          [disabled]="to.disabled"
          [label]="to.translateMultiselectLabel | observablePipe | async"
          [inputId]="field.key"
          [sortAlphabetically]="to.sortAlphabetically"
          [filter]="to.filter || defaultOptions.templateOptions.filter"
          [filterBy]="to.filterBy || defaultOptions.templateOptions.filterBy"
          [filterPlaceHolder]="to.filterPlaceHolder"
          [appendTo]="to.appendTo"
          (change)="to.change ? to.change(field, $event) : null"
          [scrollHeight]="to.scrollHeight || defaultOptions.templateOptions.scrollHeight"
          [maxSelectedLabels]="0"
          [displaySelectedLabel]="to.displaySelectedLabel || true"
          [readonly]="to.readOnly"></cap-multi-select>
      }
      @if (!to.disabled) {
        <h5 class="group-selected-options-title">
          {{ to.translateSelectedItemsTitle | observablePipe | async }}
        </h5>
      }
      @if (formControl.value; as selectedItems) {
        @if (selectedItems.length) {
          <ul class="group-selected-options-list">
            @for (item of selectedItems | sortByLabel: options; track item) {
              <li class="group-selected-options-item">
                <span>{{ item | labelFromOptions: options }}</span>
                @if (!to.disabled) {
                  <i (click)="remove(item, $event)" class="fas fa-trash color-danger"> </i>
                }
              </li>
            }
          </ul>
        } @else {
          <span class="no-content">
            {{ 'radboud.select-card.no-items-selected.label' | translate }}
          </span>
        }
      }
    }
  `,
  styleUrls: ['multi-select-group.formly-type.scss'],
  standalone: true,
  imports: [
    CapturumMultiSelectModule,
    FormlyModule,
    FormsModule,
    ReactiveFormsModule,
    AsyncPipe,
    TranslateModule,
    CapturumSharedModule,
    LabelFromOptionsPipe,
    SortByLabelPipe,
  ],
})
export class MultiSelectGroupedFormlyTypeComponent extends FieldType {
  public defaultOptions = {
    templateOptions: {
      filter: true,
      filterBy: 'label',
      appendTo: null,
      scrollHeight: '200px',
      overlayVisible: false,
      dataKey: 'value',
      defaultLabel: 'Choose',
      onChange: null,
    },
  };

  @ViewChild(CapturumMultiSelectComponent)
  public multiselect: CapturumMultiSelectComponent;

  public remove(item: MapItem, event: MouseEvent): void {
    this.multiselect.pMultiSelect.onOptionSelect({
      originalEvent: event,
      option: item,
    });
  }
}
