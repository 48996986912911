import { QuillConfig } from 'ngx-quill';
import { ImageData } from 'quill-image-drop-and-paste';

export const editorSettings: QuillConfig = {
  placeholder: '',
  readOnly: false,
  formats: [
    'align',
    'bold',
    'code-block',
    'color',
    'float',
    'height',
    'image',
    'italic',
    'link',
    'list',
    'placeholder',
    'calltoaction',
    'size',
    'underline',
    'width',
    'imageBlot',
    'header',
  ],
  modules: {
    // fix for quill do not add extra <p> before list
    clipboard: {
      matchVisual: false,
    },
    toolbar: {
      container: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ size: ['small', false, 'large', 'huge'] }],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['link', 'image'],
      ],
      handlers: {
        image: imageHandler,
      },
    },
    imageUploader: {
      upload: (file) => {
        return new Promise((resolve, reject) => {});
      },
    },
    imageActions: {},
    imageFormats: {},
    customImageDropAndPaste: {
      handler: imageDropOrPasteHandler,
    },
  },
};

// Handler for image, enter image url instead of upload.
function imageHandler() {
  const tooltip = this.quill.theme.tooltip;
  const originalSave = tooltip.save;
  const originalHide = tooltip.hide;

  tooltip.save = function () {
    const range = this.quill.getSelection(true);
    const value = this.textbox.value;

    if (value) {
      this.quill.insertEmbed(range.index, 'image', value, 'user');
    }
  };
  // Called on hide and save.
  tooltip.hide = function () {
    tooltip.save = originalSave;
    tooltip.hide = originalHide;
    tooltip.hide();
  };
  tooltip.edit('image');
  tooltip.textbox.placeholder = 'Image URL';
}

function imageDropOrPasteHandler(dataUrl: string, type: string, imageData: ImageData) {
  const caretPosition = this.quill?.quillEditor?.getSelection(true);

  this.quill?.quillEditor?.insertEmbed(caretPosition?.index || 0, 'image', dataUrl);
}
